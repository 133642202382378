export enum CampaignStatusEnum {
    Draft = 'Draft',
    Ready = 'Ready',
    Sending = 'Sending',
    Paused = 'Paused',
    'Pending Approval' = 'Pending Approval',
    Sent = 'Sent',
    Failed = 'Failed',
}

export interface ScheduleCampaignDto {
    schedule_timestamp: number
    force_send: boolean
}

export interface IMessageEmail {
    id: number
    // mode: 'Html' | 'Drago' | 'Empty'
    email_name: string
    pre_header: string | null
    from_name: string
    from_email: string
    reply_to_name: string
    reply_to_email: string
    subject: string
    html_content: string
    plain_content: string
    content_type: string
    fetch_plain_url: string
    fetch_url: string
    footer_id: string
    footer?: {
        data: {
            id: number
            user_id: number
            name: string
            html: string
            plain: string
            default_footer: boolean
        }
    }
    project_id: string
    user_id: string
    thumbnail_url: string
}

export interface IMessageSms {
    id: number
    from: string
    body: string
}

export interface SplitTest {
    id: any
    campaign_id: any
    status: string
    test_duration: number
    test_size: number
    winner: string
    winner_message: any
}

export interface ICampaign {
    split_test: {data: SplitTest} | null
    click: {
        rate: number
        unique: number
    }
    created_at: string
    ctor: number
    delivered: {
        total: number
    }
    id: number
    estimate_sent: number
    content_type: 'email' | 'sms' | 'split_test'
    message: {
        data: IMessageEmail | IMessageSms
    }
    name: string
    open: {
        ratio: number
        unique: number
    }
    schedule_type: string
    scheduled: string | null
    sent_finish: string
    sent_report: 'NotAvailable' | 'Processing' | 'Completed'
    sent_start: string
    settings: {
        ecommerce_tracking: {
            active: boolean
            ttl: string
        }
        goal_tracking: boolean
        google_analytics_settings: {
            utm_source: string
            utm_medium: string
            utm_campaign: string
        }
    }
    status: CampaignStatusEnum
    status_reason: string
    tags: any[]
    total_recipients: number
    total_sent: number
    type: 'Booster' | 'Campaign'
    user_id: number
    thumbnailUrl: string
}

export interface ICampaignFromGetAll {
    click: {
        rate: number
        unique: number
    }
    created_at: string
    ctor: number
    delivered: {
        total: number
    }
    id: number
    estimate_sent: number
    content_type: 'email' | 'sms' | 'split_test'
    email?: {
        data: IMessageEmail
    }
    sms?: {
        data: IMessageSms
    }
    name: string
    open: {
        ratio: number
        unique: number
    }
    schedule_type: string
    scheduled: number | null
    sent_finish: string
    sent_start: string
    settings: {
        ecommerce_tracking: {
            active: boolean
            ttl: string
        }
        goal_tracking: boolean
        google_analytics_settings: {
            utm_source: string
            utm_medium: string
            utm_campaign: string
        }
    }
    split_test: {data: SplitTest} | null
    status: CampaignStatusEnum
    tags: any[]
    total_recipients: number
    total_sent: number
    type: 'Booster' | 'Campaign'
    user_id: number
    status_reason: string | null
    thumbnailUrl: string
}
